import Cookies from "js-cookie";
import {
  CookiesKeys,
  LocalStorageKeys,
  SessionStorageKeys,
} from "@/enums/Storage";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { Environment } from "@/enums/Config";

const cookieOptions = {
  expires: 365,
  samesite: "lax",
  domain: "",
};
if (MyRentalsConfig.env().ENVIRONMENT !== Environment.LOCAL) {
  cookieOptions.domain = `.${document.domain?.split(".").slice(-2).join(".")}`;
}
class Storage {
  static setLocalStorageItem<T>(itemName: LocalStorageKeys, value: T) {
    localStorage.setItem(itemName.toString(), JSON.stringify(value));
  }

  static getLocalStorageItem(
    itemName: LocalStorageKeys,
    fallback?: string | null
  ) {
    return (
      JSON.parse(localStorage.getItem(itemName.toString()) || "{}") || fallback
    );
  }

  static removeLocalStorageItem(itemName: LocalStorageKeys) {
    localStorage.removeItem(itemName.toString());
  }

  static setSessionStorageItem<T>(itemName: SessionStorageKeys, value: T) {
    sessionStorage.setItem(itemName.toString(), JSON.stringify(value));
  }

  static getSessionStorageItem(
    itemName: SessionStorageKeys,
    fallback?: string | null
  ) {
    return (
      JSON.parse(sessionStorage.getItem(itemName.toString()) || "{}") ||
      fallback
    );
  }

  static removeSessionStorageItem(itemName: SessionStorageKeys) {
    sessionStorage.removeItem(itemName.toString());
  }

  static getCookiesItem(itemName: CookiesKeys, fallback = null): string | null {
    return Cookies.get(itemName) || fallback;
  }

  static getCookiesItemWithId(
    itemName: CookiesKeys,
    itemId: string,
    fallback = null
  ): string | null {
    return Cookies.get(`${itemName}${itemId}`) || fallback;
  }
  static setCookiesItem(
    itemName: CookiesKeys,
    value: string,
    { expires } = { expires: 365 }
  ) {
    Cookies.set(itemName, value, { ...cookieOptions, expires });
  }

  static setCookiesItemWithId(
    itemName: CookiesKeys,
    itemId: string,
    value: string | number,
    { expires } = { expires: 365 }
  ) {
    Cookies.set(`${itemName}${itemId}`, value.toString(), {
      ...cookieOptions,
      expires,
    });
  }

  static removeCookiesItem(itemName: CookiesKeys) {
    Cookies.remove(itemName, cookieOptions);
  }
}

export default Storage;
