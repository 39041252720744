import { IOwnerState } from "@/models/Store";
import { IOwnerProperties } from "@/models/OwnerProperties";

const state: IOwnerState = {
  ownerProperties: undefined,
};

export default {
  namespaced: true,
  state,
  mutations: {
    SET_OWNER_PROPERTIES: (
      state: IOwnerState,
      ownerProperties: IOwnerProperties
    ) => {
      state.ownerProperties = ownerProperties;
    },
  },
  actions: {},
  getters: {
    ownerProperties: (state: IOwnerState) => state.ownerProperties,
  },
};
