import MyRentalsRequest from "@/api/request";
import { IUserProfile, IUserProfileUpdatable } from "@/models/User";
import { IImpersonate } from "@/models/Impersonate";
import { ApiErrorCode } from "@/enums/ErrorCode";
import HousfyError from "@/classes/HousfyError";
import Storage from "@/plugins/storage";
import { CookiesKeys } from "@/enums/Storage";

export default class AuthApi {
  private request: MyRentalsRequest;

  constructor(request: MyRentalsRequest) {
    this.request = request;
  }

  async impersonate(userId: number, forceToken: string): Promise<IImpersonate> {
    if (isNaN(userId))
      throw new HousfyError(ApiErrorCode.AUTH_INVALID_IMPERSONATION);
    const url = `/user/v1/auth/impersonate/${userId}`;
    return this.request.call<IImpersonate>({ method: "POST", url }, forceToken);
  }

  async retrieveProfile(forceToken: string): Promise<IUserProfile> {
    const url = `/user/v1/profile`;
    return this.request.call<IUserProfile>({ method: "GET", url }, forceToken);
  }

  async updateProfile(data: IUserProfileUpdatable): Promise<IUserProfile> {
    const url = "/user/v1/profile";
    const updatedProfile = await this.request.call<IUserProfile>({
      method: "PATCH",
      url,
      data,
    });
    Storage.setCookiesItem(
      CookiesKeys.HOUSFY_LAST_PROFILE_CHANGE,
      new Date().getTime().toString()
    );
    return updatedProfile;
  }
}
