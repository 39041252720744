import MyMortgagesRequest from "./request";
import { IAdminOptionsResponse } from "@/models/Experiments";

export default class ExperimentsAPI {
  private request: MyMortgagesRequest;

  constructor(request: MyMortgagesRequest) {
    this.request = request;
  }

  async getExperimentOptions(adminid: number): Promise<IAdminOptionsResponse> {
    const url = `public/v1/feature-toggle/${adminid}`;
    return this.request.call<IAdminOptionsResponse>({ method: "GET", url }, "");
  }
}
