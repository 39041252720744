/* eslint-disable no-unused-vars */

export enum CookiesKeys {
  USER_LANGUAGE = "housfy_user_language",
  USER_API_TOKEN = "housfy_api_token",
  USER_UUID = "housfy_user_uuid",
  USER_ID = "housfy_user_id",
  BROWSER_UUID = "housfy_browser_uuid",
  IS_AUTOMATIC_TESTING_RF = "is_automation_testing_RF",
  EXPERIMENT = "housfy_optimize_experiment_",
  UTMS = "__utmzz",
  HOUSFY_LAST_PROFILE_CHANGE = "housfy_last_profile_change",
}

export enum LocalStorageKeys {
  IFRAME_URL = "iframeUrl",
}

export enum SessionStorageKeys {
  USER_PROFILE = "housfy_user_profile",
  IMPERSONATE_PROFILE = "housfy_impersonate_profile",
  ADMIN_USER_ID = "housfy_admin_user_id",
  ADMIN_USER_UUID = "housfy_admin_user_uuid",
}
