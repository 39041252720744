import * as Sentry from "@sentry/browser";
import GoogleAnalyticsService from "@/services/marketing-services/GoogleAnalyticsService";
import DOMService from "@/services/DOMService";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { Country, Environment } from "@/enums/Config";

export default class GoogleService {
  private analyticsService: GoogleAnalyticsService;

  constructor() {
    this.analyticsService = new GoogleAnalyticsService();
  }

  public analytics(): GoogleAnalyticsService {
    return this.analyticsService;
  }

  public async init() {
    try {
      window.dataLayer = [];
      await this.loadTagManager(
        !(MyRentalsConfig.env().TRACK_EVENTS === "true")
      );
      this.debugInit();
    } catch (err) {
      console.error("GoogleService init error");
      Sentry.captureException("GoogleService init error: " + err);
    }
  }

  private loadTagManager(debug = false) {
    try {
      return new Promise((resolve) => {
        if (window.dataLayer) resolve(null);

        const GTM = debug ? "GTM-K929ZZR" : this.getGTMForCountry();
        DOMService.loadJS(
          `h-gtm-${MyRentalsConfig.country()}`,
          "https://www.googletagmanager.com/gtm.js?id=" + GTM,
          () => {
            window.dataLayer = window.dataLayer || [];
            this.addToDataLayer({
              "gtm.start": new Date().getTime(),
              event: "gtm.js",
              project: "myh",
            });
            this.debugTagManagerLoaded(GTM);
            resolve(null);
          },
          () => {
            window.dataLayer = window.dataLayer || [];
            resolve(null);
          }
        );
      });
    } catch (err) {
      console.error("GoogleService loadTagManager error");
      Sentry.captureException("GoogleService loadTagManager error: " + err);
    }
  }

  private getGTMForCountry() {
    const isProd = MyRentalsConfig.env().ENVIRONMENT === Environment.PROD;
    switch (MyRentalsConfig.country()) {
      case Country.IT:
        return "GTM-N232XX7";
      case Country.PT:
        return "GTM-5H4FMQ9";
      case Country.ES:
      default:
        return isProd ? "GTM-WFLJCWB" : "GTM-K929ZZR";
    }
  }

  private addToDataLayer(data: any) {
    if (!window?.dataLayer) return;
    window.dataLayer.push(data);
  }

  private debugInit() {
    try {
      if (MyRentalsConfig.env().ENVIRONMENT === Environment.PROD) return;
      console.info("[Google] Init " + MyRentalsConfig.country());
    } catch (err) {
      console.error("GoogleService debugInit error");
      Sentry.captureException("GoogleService debugInit error: " + err);
    }
  }

  private debugTagManagerLoaded(GTM: string) {
    try {
      if (MyRentalsConfig.env().ENVIRONMENT === Environment.PROD) return;
      console.info("[Google] TagManagerLoaded " + GTM);
    } catch (err) {
      console.error("GoogleService debugTagManagerLoaded error");
      Sentry.captureException(
        "GoogleService debugTagManagerLoaded error: " + err
      );
    }
  }
}
