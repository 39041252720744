import Storage from "@/plugins/storage";
import api from "@/api";
import { CookiesKeys, SessionStorageKeys } from "@/enums/Storage";
import { IUserProfile, IUserProfileSessionStorage } from "@/models/User";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { IImpersonateProfileSessionStorage } from "@/models/Impersonate";
import { IMPERSONATE_URL_PREFIX } from "@/constants/Impersonate";
import { resolveRoutePath } from "@/router";
import { ApiErrorCode } from "@/enums/ErrorCode";
import { IHousfyError } from "@/models/HousfyError";

// DON'T TOUCH THIS SERVICE EVER, OR MAINTAIN OTHER PROJECTS AS WELL
class AuthService {
  // IS LOGGED IN
  isLogged(): boolean {
    return !!this.currentUser.id;
  }

  // CURRENT USER
  get currentUser(): IUserProfile {
    if (this.isImpersonate()) {
      const userProfile = this.getImpersonateProfileSessionStorage();
      if (userProfile?.token) delete userProfile.token;
      if (userProfile?.tokenOfAdminUser) delete userProfile.tokenOfAdminUser;
      if (userProfile?.timestamp) delete userProfile.timestamp;
      return userProfile;
    }
    const userProfile = this.getUserProfileSessionStorage();
    if (userProfile?.token) delete userProfile.token;
    if (userProfile?.timestamp) delete userProfile.timestamp;
    return userProfile;
  }

  // AUTH GUARD
  async authGuard(
    isPublic = false
  ): Promise<"LOGIN" | "NOT_ALLOWED" | "PROFILE" | "OK"> {
    const cookiesToken = Storage.getCookiesItem(CookiesKeys.USER_API_TOKEN);
    if (!cookiesToken) {
      if (!isPublic) this.redirectToLogin();
      else {
        Storage.removeSessionStorageItem(SessionStorageKeys.USER_PROFILE);
        Storage.removeSessionStorageItem(
          SessionStorageKeys.IMPERSONATE_PROFILE
        );
      }
      return "LOGIN";
    }

    const isImpersonate = this.isImpersonate();

    if (isImpersonate) {
      const impersonateUserId = this.impersonateUserId();
      const sessionStorageProfile = this.getImpersonateProfileSessionStorage();
      const lastProfileChange = this.getLastProfileChange();

      const isSameUser =
        cookiesToken === sessionStorageProfile?.tokenOfAdminUser &&
        impersonateUserId === sessionStorageProfile?.id;
      const hasBeenUpdated =
        isSameUser &&
        lastProfileChange > (sessionStorageProfile?.timestamp || 0);

      if (isSameUser && !hasBeenUpdated) return "OK";

      try {
        await this.retrieveImpersonateProfile(impersonateUserId, cookiesToken);
        if (hasBeenUpdated) return "OK";
        else return "PROFILE";
      } catch (err) {
        Storage.removeSessionStorageItem(
          SessionStorageKeys.IMPERSONATE_PROFILE
        );
        const error: IHousfyError = err as IHousfyError;
        if (error.code === ApiErrorCode.AUTH_INVALID_IMPERSONATION)
          this.redirectToInvalidImpersonatedUser();
        else this.redirectToNoImpersonatePermission();
        return "NOT_ALLOWED";
      }
    } else {
      const sessionStorageProfile = this.getUserProfileSessionStorage();
      const lastProfileChange = this.getLastProfileChange();

      const isSameUser = cookiesToken === sessionStorageProfile?.token;
      const hasBeenUpdated =
        isSameUser &&
        lastProfileChange > (sessionStorageProfile?.timestamp || 0);

      if (isSameUser && !hasBeenUpdated) return "OK";

      try {
        await this.retrieveUserProfile(cookiesToken);
        if (hasBeenUpdated) return "OK";
        else return "PROFILE";
      } catch (error) {
        Storage.removeSessionStorageItem(SessionStorageKeys.USER_PROFILE);
        if (!isPublic) this.redirectToLogin();
        return "LOGIN";
      }
    }
  }

  // GET BEARER TOKEN
  async getAuthToken() {
    const isImpersonate = this.isImpersonate();
    const cookiesToken = Storage.getCookiesItem(CookiesKeys.USER_API_TOKEN);
    const impersonateToken = this.getImpersonateProfileSessionStorage()?.token;

    const guardResult = await this.authGuard();
    if (guardResult === "OK") {
      if (isImpersonate) return impersonateToken;
      return cookiesToken;
    }
    if (guardResult === "PROFILE") location.reload();
  }

  // RETRIEVE PROFILE
  async retrieveUserProfile(token: string) {
    const userProfile = await api.auth().retrieveProfile(token);
    Storage.setSessionStorageItem(SessionStorageKeys.USER_PROFILE, {
      token: token,
      timestamp: new Date().getTime(),
      ...userProfile,
    });
  }
  private async retrieveImpersonateProfile(userId: number, token: string) {
    const currentUserProfile = await api.auth().retrieveProfile(token);
    Storage.setSessionStorageItem(SessionStorageKeys.USER_PROFILE, {
      token: token,
      timestamp: new Date().getTime(),
      ...currentUserProfile,
    });
    if (currentUserProfile.id === userId) return this.redirectToNoImpersonate();
    const impersonateResponse = await api.auth().impersonate(userId, token);
    const impersonateToken = impersonateResponse.token;
    const impersonateProfile = await api
      .auth()
      .retrieveProfile(impersonateToken);

    Storage.setSessionStorageItem(SessionStorageKeys.IMPERSONATE_PROFILE, {
      tokenOfAdminUser: token,
      token: impersonateToken,
      timestamp: new Date().getTime(),
      ...impersonateProfile,
    });
    Storage.setSessionStorageItem(
      SessionStorageKeys.ADMIN_USER_ID,
      impersonateResponse.adminId
    );

    Storage.setSessionStorageItem(
      SessionStorageKeys.ADMIN_USER_UUID,
      impersonateResponse.adminUuid
    );

    return impersonateProfile;
  }

  // SESSION STORAGE
  private getUserProfileSessionStorage(): IUserProfileSessionStorage {
    const key = SessionStorageKeys.USER_PROFILE;
    return Storage.getSessionStorageItem(key);
  }
  private getImpersonateProfileSessionStorage(): IImpersonateProfileSessionStorage {
    const key = SessionStorageKeys.IMPERSONATE_PROFILE;
    return Storage.getSessionStorageItem(key);
  }

  private getLastProfileChange(): number {
    const lastProfileChange = Storage.getCookiesItem(
      CookiesKeys.HOUSFY_LAST_PROFILE_CHANGE
    );
    return lastProfileChange ? Number(lastProfileChange) : 0;
  }

  // IMPERSONATE
  isImpersonate(): boolean {
    return location.pathname.startsWith(`/${IMPERSONATE_URL_PREFIX}/`);
  }
  impersonateUserId(): number {
    return Number(location.pathname.split("/")[2]);
  }

  // REDIRECTS
  private redirectToNoImpersonate(): void {
    const userId = this.impersonateUserId();
    /* eslint-disable-next-line xss/no-location-href-assign */
    location.href = location.href.replace("/imp/" + userId, "");
  }
  private redirectToLogin(): void {
    const next = encodeURIComponent(window.location.href);
    /* eslint-disable-next-line xss/no-location-href-assign */
    location.href = resolveRoutePath(MYHExternalRoutes.login(next));
  }
  private redirectToInvalidImpersonatedUser(): void {
    /* eslint-disable-next-line xss/no-location-href-assign */
    location.href = resolveRoutePath(
      MYHExternalRoutes.invalidImpersonatedUser()
    );
  }
  private redirectToNoImpersonatePermission(): void {
    /* eslint-disable-next-line xss/no-location-href-assign */
    location.href = resolveRoutePath(
      MYHExternalRoutes.noImpersonatePermission()
    );
  }
}

export default new AuthService();
