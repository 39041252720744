import { IContactState } from "@/models/Store";
import { IContactPerson } from "@/models/ContactPerson";

const state: IContactState = {
  contactPerson: undefined,
};

export default {
  namespaced: true,
  state,
  mutations: {
    SET_CONTACT_PERSON: (
      state: IContactState,
      contactPerson: IContactPerson
    ) => {
      state.contactPerson = contactPerson;
    },
  },
  actions: {},
  getters: {
    contactPerson: (state: IContactState) => state.contactPerson,
  },
};
