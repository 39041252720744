import AuthApi from "./auth";
import MyRentalsRequest from "./request";
import ExperimentsAPI from "./experiments";
import LeadApi from "@/api/lead";
import OwnerApi from "./owner";
import PropertyApi from "./property";
import PropertyStatusCategory from "@/api/propertyStatusCategory";
import PropertyDocumentsApi from "@/api/propertyDocuments";
import ContactApi from "@/api/contact";
import RentalLeasingsApi from "@/api/rentalLeasings";

class ApiClient {
  public request: MyRentalsRequest;
  constructor() {
    this.request = new MyRentalsRequest();
  }

  auth() {
    return new AuthApi(this.request);
  }

  experiments() {
    return new ExperimentsAPI(this.request);
  }

  lead() {
    return new LeadApi(this.request);
  }

  owner() {
    return new OwnerApi(this.request);
  }

  property() {
    return new PropertyApi(this.request);
  }

  propertyStatusCategory() {
    return new PropertyStatusCategory(this.request);
  }

  propertyDocuments() {
    return new PropertyDocumentsApi(this.request);
  }

  contact() {
    return new ContactApi(this.request);
  }

  rentalLeasings() {
    return new RentalLeasingsApi(this.request);
  }
}

export default new ApiClient();
