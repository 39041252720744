import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ICall } from "@/models/Api";
import AuthService from "@/services/AuthService";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import HousfyError from "@/classes/HousfyError";
import { ApiErrorCode, ErrorCode } from "@/enums/ErrorCode";
import CacheService from "@/services/CacheService";

class MyRentalsRequest {
  protected api: AxiosInstance;
  constructor() {
    this.api = axios.create({
      baseURL: MyRentalsConfig.env().HOUSFY_API_URL + "/api",
      timeout: 10000,
    });
  }

  async call<T>(
    { method = "GET", url, data, params, timeout = 10000 }: ICall,
    forceToken?: string
  ): Promise<T> {
    const token = forceToken || (await AuthService.getAuthToken());
    if (!token)
      return Promise.reject(
        new HousfyError(ErrorCode.NO_TOKEN, "The user has no token")
      );

    const options: AxiosRequestConfig = {
      method,
      url,
      data,
      params,
      timeout,
      headers: { Authorization: `Bearer ${token}` },
    };

    CacheService.checkUserToken(token);

    const isCacheable = CacheService.isCacheable(url, method);
    const cachedPromise = CacheService.getFromCache(url);

    if (isCacheable && cachedPromise) return cachedPromise;

    const promise = new Promise<T>((resolve, reject) => {
      this.api(options)
        .then((response) => resolve(response.data))
        .catch((error) => {
          const errorData = error.response?.data || {};
          const code = errorData.code || ApiErrorCode.UNKNOWN;
          const status = error.response?.status;
          const message = errorData.message
            ? errorData.message
            : error.response?.statusText;
          reject(new HousfyError(code, message, status, errorData));
        });
    });

    if (isCacheable) CacheService.addToCache(url, promise);

    return promise.catch((error) => {
      CacheService.removeFromCache(url);
      return Promise.reject(error);
    });
  }
}
export default MyRentalsRequest;
