//when a api call is generated to create a lead, it must include a stitchEvent with ("lead", `RE_/RNT_/MO_${leadEntryId}`) as params
//if the endpoints of MO and RNT don't return yet a leadEntryId, it will be used mortgageId and rentalLeadId respectivelly
import MyRentalsRequest from "@/api/request";
import { IRentalsLead } from "@/models/IRentalsLead";
import { IRentalsLeadResponse } from "@/models/IRentalsLeadResponse";
import Stitch from "@/services/StitchService";
import UtmService from "@/services/UtmService";

export default class LeadApi {
  private request: MyRentalsRequest;

  constructor(request: MyRentalsRequest) {
    this.request = request;
  }

  async rentals(data: IRentalsLead): Promise<IRentalsLeadResponse> {
    const url = `/user/v1/public/rentals/leads/action/register`;
    const utms = UtmService.getUtmsFromUrlOrCookie();

    const response = await this.request.call<IRentalsLeadResponse>({
      method: "POST",
      url,
      data: { ...data, ...utms },
    });

    if (response) Stitch.trackEvent("lead", `RNT_${response.leadEntryId}`);

    return response;
  }
}
