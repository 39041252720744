import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import MyRentalsConfig from "@/services/MyRentalsConfig";

Vue.use(VueGoogleMaps, {
  load: {
    key: MyRentalsConfig.env().GOOGLE_MAPS_API_KEY_SECRET,
    libraries: "places,geometry",
  },

  installComponents: true,
});
