export default [
  {
    path: "/*",
    name: "not-found",
    component: () =>
      import(
        /* webpackChunkName: "errors" */ "../views/errors/NotFound404.vue"
      ),
  },
];
