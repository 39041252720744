import {
  IExperimentsByPercentage,
  IExperimentsByUserId,
} from "@/models/Experiments";

export const EXPERIMENTS_BY_PERCENTAGE: IExperimentsByPercentage = {};

export const EXPERIMENTS_BY_USER_ID: IExperimentsByUserId = {
  MYRENTALS_BETA_ROLLOUT: {
    id: "MYRENTALS_BETA_ROLLOUT",
    name: "MYRENTALS_BETA_ROLLOUT",
    users: [718, 15897, 838090, 109224, 685949, 496107],
    variant: "B",
  },
};
