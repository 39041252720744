import Storage from "@/plugins/storage";
import { CookiesKeys, SessionStorageKeys } from "@/enums/Storage";
import * as Sentry from "@sentry/browser";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import UtmService from "@/services/UtmService";
import AuthService from "@/services/AuthService";
import ExperimentService from "./ExperimentService";

const TRACK_STITCH = true;

interface IPayload {
  user_id: number | undefined;
  user_uuid: string | undefined | null;
  admin_user_id: number | undefined;
  admin_user_uuid: string | undefined;
  browser_uuid: string;
  domain: string;
  full_url: string;
  product_name: string;
  product_id: number;
  area: string;
  event_name: string;
  event_detail: string | undefined;
  event_created_at: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  gclid?: string;
}

export default class StitchService {
  static init() {
    if (!document) return;
    document.body.addEventListener<"click">("click", (event) => {
      try {
        const findElementWithTrackingFromTarget = (
          element: any
        ): HTMLElement | null => {
          if (element.dataset?.tracking) return element;

          return element.parentNode
            ? findElementWithTrackingFromTarget(element.parentNode)
            : null;
        };
        const element = findElementWithTrackingFromTarget(event.target);
        if (element?.dataset?.tracking)
          StitchService.trackEvent("click", element.dataset.tracking);
      } catch (err) {
        console.error("[Stitch] Stitch Click tracking error");
        Sentry.captureException(`[Stitch] Stitch Click tracking error ${err}`);
      }
    });
    window.addEventListener("google_place_changed", ((event: {
      target: any;
      detail: string;
    }) => {
      try {
        const eventName = "input_autocomplete_" + event.detail;
        StitchService.trackEvent("click", eventName);
      } catch (err) {
        console.error("[Stitch] Stitch Click tracking error");
        Sentry.captureException(`[Stitch] Stitch Click tracking error ${err}`);
      }
    }) as () => any);
  }

  static trackEvent(eventName: string, eventDetail: string | null = null) {
    try {
      if (
        eventName !== "page_view" &&
        (!this.cookiesAvailability() ||
          !this.localStorageAvailability() ||
          !this.sessionStorageAvailability())
      )
        return;

      const userId = AuthService.currentUser?.id;
      const userUuid = AuthService.currentUser?.uuid;
      const adminUserId =
        this.sessionStorageAvailability() && AuthService.isImpersonate()
          ? Storage.getSessionStorageItem(SessionStorageKeys.ADMIN_USER_ID)
          : undefined;
      const adminUserUuid =
        this.sessionStorageAvailability() && AuthService.isImpersonate()
          ? Storage.getSessionStorageItem(SessionStorageKeys.ADMIN_USER_UUID)
          : undefined;
      const utms = this.cookiesAvailability()
        ? UtmService.getUtmsFromUrlOrCookie()
        : Object.keys(UtmService.extractUTMSfromURL()).length !== 0
        ? UtmService.extractUTMSfromURL()
        : {
            utmSource: "(direct)",
            utmMedium: "(none)",
            utmCampaign: "(not set)",
          };
      const payload = {
        user_id: userId ? Number(userId) : undefined,
        user_uuid: userId ? userUuid : undefined,
        admin_user_id: adminUserId ? Number(adminUserId) : undefined,
        admin_user_uuid: adminUserId ? adminUserUuid : undefined,
        browser_uuid: this.getAndSetBrowserUuid(),
        domain: location.host,
        full_url: location.href,
        product_name: "housfy",
        product_id: 1,
        area: "private_web",
        event_name: eventName,
        event_detail: eventDetail || undefined,
        event_created_at: new Date().toISOString(),
        device_type: document.device,
        user_agent: window.navigator?.userAgent,
        utm_source: utms?.utmSource || undefined,
        utm_medium: utms?.utmMedium || undefined,
        utm_campaign: utms?.utmCampaign || undefined,
        utm_term: utms?.utmTerm || undefined,
        utm_content: utms?.utmContent || undefined,
        gclid: utms?.googleClientId || undefined,
        experiments: this.getExperiments(),
      };
      this.sendEvent(payload);
    } catch (err) {
      console.error(`[Stitch] Stitch ${eventName} error`);
      Sentry.captureException(`[Stitch] Stitch ${eventName} error ${err}`);
    }
  }

  static localStorageAvailability(): boolean {
    try {
      return !!window.localStorage;
    } catch {
      return false;
    }
  }

  static sessionStorageAvailability(): boolean {
    try {
      return !!window.sessionStorage;
    } catch {
      return false;
    }
  }

  static cookiesAvailability(): boolean {
    try {
      return !!navigator.cookieEnabled;
    } catch {
      return false;
    }
  }

  static getAndSetBrowserUuid(): string {
    let id = this.cookiesAvailability()
      ? Storage.getCookiesItem(CookiesKeys.BROWSER_UUID)
      : undefined;
    if (id) return id;
    id = this.generateUuid();
    if (this.cookiesAvailability())
      Storage.setCookiesItem(CookiesKeys.BROWSER_UUID, id);
    return id;
  }

  static generateUuid = () => {
    let d = new Date().getTime();
    let d2 = 0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
    });
  };

  static debugStitchEvent(
    eventName: string,
    eventDetail: string | undefined = undefined
  ) {
    if (MyRentalsConfig.env().ENVIRONMENT === "production") return;
    console.info(`[Stitch] Stitch ${eventName} ${eventDetail}`);
  }

  static sendEvent(payload: IPayload) {
    if (!TRACK_STITCH) return;
    if (MyRentalsConfig.env().ENVIRONMENT !== "production") {
      this.debugStitchEvent(payload.event_name, payload.event_detail);
    }
    const url = MyRentalsConfig.env().STITCH_URL || "";
    fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }).catch(function () {
      console.error(`[Stitch] Fetch error`);
    });
  }

  static getExperiments(): string | undefined {
    if (!this.cookiesAvailability()) return undefined;
    const activeExperiments = ExperimentService.activeExperiments;
    if (activeExperiments.length === 0) return undefined;
    const experimentsToSend = activeExperiments.map(
      (experiment) =>
        `${experiment.id}_-_${experiment.name}_-_${experiment.variant}`
    );
    const formattedExperiments = experimentsToSend
      ?.toString()
      .replace(",", ";");

    return formattedExperiments;
  }
}
