import Vue from "vue";
import App from "@/App.vue";
import "@/plugins/sentry";
import "@/plugins/maps";
import router from "@/router";
import store from "@/store";
import i18n from "@/plugins/i18n";
import "@/assets/css/tailwind.css";
import "@/assets/sass/app.scss";
import Storage from "@/plugins/storage";
import { CookiesKeys } from "@/enums/Storage";
import MyRentalsConfig from "./services/MyRentalsConfig";
import "@/plugins/filters";
import "@/plugins/mixins";

Vue.config.productionTip = false;

const localeFromCookies = Storage.getCookiesItem(CookiesKeys.USER_LANGUAGE);
i18n.locale = localeFromCookies ?? MyRentalsConfig.country();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
