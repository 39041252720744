/* eslint-disable no-unused-vars */
export enum Country {
  ES = "es",
  IT = "it",
  PT = "pt",
}

export enum Environment {
  LOCAL = "local",
  PRE = "pre",
  PROD = "production",
}
