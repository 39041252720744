import MyRentalsRequest from "@/api/request";
import { ISignedContract } from "@/models/Contracts";

const baseURL = `user/v1/rentals/my-housfy/rental-leasing`;

export default class RentalLeasingsApi {
  private request: MyRentalsRequest;

  constructor(request: MyRentalsRequest) {
    this.request = request;
  }

  async retrieveSignedContracts(
    rentalLeasingId: number
  ): Promise<ISignedContract[]> {
    const url = `/${baseURL}/${rentalLeasingId}/rental-leasing-signed-contracts`;
    return this.request.call<ISignedContract[]>({ method: "GET", url });
  }
}
