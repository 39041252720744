module.exports = {
  purge: [
    "./index.html",
    "./src/**/*.{vue,js,ts,jsx,tsx}",
    "./node_modules/housfy-ui-lib/**/*.{vue,js,ts,jsx,tsx}",
  ],
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      xs: "656px", // appointment form: 320px (day selector) + 16px (gap) + 320px (hour selector)
      xsmax: { max: "767px" },
      sm: "768px",
      smmax: { max: "1023px" },
      md: "1024px",
      mdmax: { max: "1279px" },
      lg: "1280px",
    },
    colors: {
      // Neutrals
      gray100: "#20314F",
      gray90: "#304760",
      gray80: "#436274",
      gray70: "#5A828B",
      gray60: "#64929B",
      gray50: "#779EA7",
      gray40: "#A3BDBF",
      gray30: "#D3E3DB",
      gray20: "#E9F1ED",
      gray10: "#F4F8F4",
      white: "#FFFFFF",
      black: "#001C45",

      // Brand
      blue80: "#036CA0",
      blue70: "#0487C8",
      blue60: "#11AEFA",
      blue40: "#91DAFD",
      blue30: "#C3EBFE",
      blue10: "#EBF8FF",

      //Semantics
      green80: "#005236",
      green70: "#006B47",
      green60: "#00875A",
      green30: "#9ADA9F",
      green10: "#E8F7F0",
      red80: "#AF2A09",
      red70: "#C7300A",
      red60: "#DE350B",
      red30: "#FAB09E",
      red10: "#FEEFEB",
      yellow80: "#B16E06",
      yellow70: "#E49B0F",
      yellow60: "#F9C258",
      yellow30: "#FEEAC3",
      yellow10: "#FEF7EB",

      //support
      purple80: "#32197B",
      purple70: "#5439A2",
      purple60: "#7150D3",
      purple30: "#CABEEF",
      purple10: "#F2EFFB",
      marine80: "#00227A",
      marine70: "#0039CC",
      marine60: "#0047FD",
      marine30: "#477BFF",
      marine10: "#EBF0FF",

      transparent: "transparent",
    },
    spacing: {
      0: "0",
      1: "1px",
      2: "2px",
      4: "4px",
      5: "5px",
      6: "6px",
      8: "8px",
      10: "10px",
      12: "12px",
      14: "14px",
      16: "16px",
      20: "20px",
      22: "22px",
      24: "24px",
      28: "28px",
      32: "32px",
      40: "40px",
      48: "48px",
      56: "56px",
      64: "64px",
      80: "80px",
      90: "90px",
      100: "100px",
      200: "200px",
      242: "242px",
      250: "250px",
      280: "280px",
      296: "296px",
      320: "320px",
      384: "384px",
      564: "564px",
      672: "672px",
      768: "768px",
      1024: "1024px",
      1280: "1280px",
      full: "100%",
      max: "max-content",
      auto: "auto",
      screenWidth: "100vw",
      screenHeight: "100vh",
      none: "none",
    },

    fontSize: {
      //if more need to be added, add them after the ones with the size prefix, indicating the number of pixels: ex 13: "13px".
      //Don't use the size prefix or "xl" or similar
      //These classes also have a scss mixin implementation (@/assets/sass/_lineheights) to set the appropiate lineheight to each fontsize
      size1: "10px",
      size2: "12px", //highlight xs / body xs / button-label s
      size3: "14px", //highlight s / body s / button-label m
      size4: "16px", //highlight m / body m / button-label l
      size5: "18px",
      size6: "20px", //highlight l / body l
      size7: "24px", //highlight xl / body xl
      size8: "28px",
      size9: "32px",
      size10: "36px",
      size11: "40px", //heading s
      size12: "48px", //heading m
      size13: "56px", //heading l
      size14: "64px", //heading xl
      size15: "80px",
    },

    fontWeight: {
      regular: 500,
      medium: 700,
      bold: 800,
      extrabold: 800,
    },
    borderRadius: {
      sm: "4px",
      md: "8px",
      lg: "16px",
      full: "100%",
      pill: "99999px",
    },
    borderWidth: {
      0: "0px",
      sm: "1px",
      md: "2px",
    },
    boxShadow: {
      sm: "0px 0px 4px rgba(0, 0, 0, 0.12)",
      md: "0px 2px 8px rgba(0, 0, 0, 0.08)",
      lg: "0px 4px 16px rgba(0, 0, 0, 0.08)",
      xl: "0px 8px 32px rgba(0, 0, 0, 0.08)",
      gray: "0px 0px 16px 0 #5a6e8b29",
      none: "none",
    },
    maxHeight: (theme) => ({
      ...theme("spacing"),
    }),
    maxWidth: (theme) => ({
      ...theme("spacing"),
    }),
    minHeight: (theme) => ({
      ...theme("spacing"),
    }),
    minWidth: (theme) => ({
      ...theme("spacing"),
    }),
  },
  variants: {
    colors: ["important"],
    margin: ["first", "last", "responsive", "odd", "even"],
    padding: ["first", "last", "responsive", "odd", "even"],
    scale: ["hover"],
    borderWidth: ["first", "last", "responsive"],
  },
};
