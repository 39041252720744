import Dinero from "dinero.js";
import { fixNumberLocale } from "@/plugins/i18n";

export interface IMoney {
  amount: string | number;
  currency: string;
}

interface ICurrencyOptions {
  style: string;
  currency: string;
  withCents: boolean;
}

class Money {
  protected amount: string | number | null;
  protected currency = "EUR";
  protected options: ICurrencyOptions;

  // As seen on Dinero.js documentation (https://dinerojs.com/module-dinero#~toFormat), it internally uses browser toLocaleString.
  // Chrome toLocaleString implementation for "es" language doesn't add point separator on thousand units (1000 to 9999, instead of 1.000 to 9.999), so we need to fix it.
  protected locale = fixNumberLocale("es");

  constructor(money: string | number) {
    let _money: IMoney;

    if (money === null || money === undefined) {
      this.amount = null;
      this.currency = "EUR";
      this.options = {
        style: "currency",
        currency: "EUR",
        withCents: false,
      };

      return;
    }

    switch (typeof money) {
      case "number":
        _money = { amount: (money * 100).toFixed(2), currency: "EUR" };
        break;
      case "string":
        _money = { amount: parseInt(money) * 100, currency: "EUR" };
        break;
      default:
        _money = money;
        break;
    }

    if (!_money) _money = { amount: 0, currency: "EUR" };

    this.amount = parseInt(_money.amount as string);
    this.options = {
      style: "currency",
      currency: _money.currency || "EUR",
      withCents: false,
    };
  }

  formatNull() {
    return "-";
  }

  format(noCurrency = false) {
    if (this.amount === null) return this.formatNull();

    const money = Dinero({
      amount: this.amount as number,
      currency: this.options.currency as Dinero.Currency,
    });

    let format = noCurrency ? "" : "$";

    format += this.options.withCents ? `0,0.00` : "0,0";

    return money.setLocale(this.locale).toFormat(format);
  }

  withOptions(options: any) {
    this.options = Object.assign(this.options, options);
    return this;
  }
}

export default Money;
