import esLocal from "@/config/es.local.json";
import esPre from "@/config/es.pre.json";
import esProd from "@/config/es.prod.json";
import itLocal from "@/config/it.local.json";
import itPre from "@/config/it.pre.json";
import itProd from "@/config/it.prod.json";
import ptLocal from "@/config/pt.local.json";
import ptPre from "@/config/pt.pre.json";
import ptProd from "@/config/pt.prod.json";
import { IConfig, IMyRentalsConfig } from "@/models/Config";
import { Country, Environment } from "@/enums/Config";
import { IEnvVars } from "@/models/Env";

const CONFIG: IMyRentalsConfig[] = [
  { country: Country.ES, env: Environment.LOCAL, config: esLocal },
  { country: Country.ES, env: Environment.PRE, config: esPre },
  { country: Country.ES, env: Environment.PROD, config: esProd },
  { country: Country.IT, env: Environment.LOCAL, config: itLocal },
  { country: Country.IT, env: Environment.PRE, config: itPre },
  { country: Country.IT, env: Environment.PROD, config: itProd },
  { country: Country.PT, env: Environment.LOCAL, config: ptLocal },
  { country: Country.PT, env: Environment.PRE, config: ptPre },
  { country: Country.PT, env: Environment.PROD, config: ptProd },
];

class MyRentalsConfig {
  config(): IConfig {
    const config = CONFIG.find(
      (configuration: IMyRentalsConfig) =>
        configuration.country === this.country() &&
        configuration.env === (this.env().ENVIRONMENT || Environment.PROD)
    );
    return config ? config.config : esProd;
  }

  env(): IEnvVars {
    // Only in this line! Don't use process.env
    // eslint-disable-next-line no-process-env
    return Object.entries(process.env)
      .filter(([key]) => key.startsWith("VUE_APP_"))
      .reduce(
        (result, [key, value]) => ({
          ...result,
          [key.replace("VUE_APP_", "")]: value,
        }),
        {}
      ) as IEnvVars;
  }

  country(): Country {
    const config = CONFIG.find(
      (configuration: IMyRentalsConfig) =>
        configuration.config.MYRENTALS_URL === location.origin
    );
    return config ? config.country : Country.ES;
  }
}
export default new MyRentalsConfig();
