
import { Component, Vue } from "vue-property-decorator";
import StitchService from "@/services/StitchService";

@Component({
  name: "app",
})
export default class App extends Vue {
  addResizeListener() {
    window.addEventListener("resize", this.parseClientWidth);
    this.parseClientWidth();
  }
  beforeMount() {
    StitchService.init();
  }
  mounted() {
    this.addResizeListener();
  }
}
