import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { version } from "../../package.json";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { Environment } from "@/enums/Config";

if (MyRentalsConfig.env().ENVIRONMENT !== Environment.LOCAL) {
  const dsnEnv =
    MyRentalsConfig.env().ENVIRONMENT === Environment.PROD
      ? "https://d992d029c8b14fcd9818d0da3dd78e49@o91045.ingest.sentry.io/6746465"
      : "https://945fd01d61c44a7abf8e2b17bb451baa@o91045.ingest.sentry.io/6228522";

  Sentry.init({
    Vue,
    dsn: dsnEnv,
    maxBreadcrumbs: 50,
    release: `housfy-rentals-${version}`,
    environment: MyRentalsConfig.env().ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      "idpiframe_initialization",
      /Non-Error/,
      "ChunkLoadError",
      "Failed to fetch",
      "JSON Parse error: Unexpected EOF",
      "Unexpected end of JSON input",
    ],
  });
}
