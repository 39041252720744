import Storage from "@/plugins/storage";
import * as Sentry from "@sentry/browser";
import { CookiesKeys } from "@/enums/Storage";
import { IUtm, IUtmObject } from "@/models/Utm";

const UTM_MAPPER = {
  utmcsr: "utmSource",
  utm_source: "utmSource",
  utmcmd: "utmMedium",
  utm_medium: "utmMedium",
  utmccn: "utmCampaign",
  utm_campaign: "utmCampaign",
  utmctr: "utmTerm",
  utm_term: "utmTerm",
  utm_keyword: "utmKeyword",
  utmkwd: "utmKeyword",
  utmcct: "utmContent",
  utm_content: "utmContent",
  utmgclid: "googleClientId",
  gclid: "googleClientId",
  other: "other",
};

class UtmService {
  public getUtmsFromUrlOrCookie(): IUtmObject {
    try {
      const utmsFromCookies = this.extractUTMSfromCookie();
      const utmsFromURL = this.extractUTMSfromURL();
      const utms: IUtmObject = {
        ...utmsFromCookies,
        ...utmsFromURL,
        gclid: utmsFromCookies.googleClientId || utmsFromURL.googleClientId,
      };

      if (!utms.utmKeyword && utms.utmTerm) utms.utmKeyword = utms.utmTerm;
      return utms;
    } catch (err) {
      console.error("UtmService getUtmsFromUrlOrCookie error");
      Sentry.captureException(
        "UtmService getUtmsFromUrlOrCookie error: " + err
      );
    }
    return {};
  }

  public extractUTMSfromURL(): IUtmObject {
    const data: IUtmObject = {};
    const params = new URLSearchParams(document.location.search.substring(1));
    params.forEach((value, key) => {
      const utm = UTM_MAPPER[key as keyof IUtm] || "other";
      data[utm as keyof IUtmObject] = value;
    });
    return data;
  }

  private extractUTMSfromCookie(): IUtmObject {
    const cookie = Storage.getCookiesItem(CookiesKeys.UTMS);
    if (!cookie)
      return {
        utmSource: "(direct)",
        utmMedium: "(none)",
        utmCampaign: "(not set)",
      };
    let data: IUtmObject = {};
    try {
      const params = cookie.split("|");
      data = params.reduce((acc: IUtmObject, chunk: string) => {
        const [key, value] = chunk.split("=");
        const utm = UTM_MAPPER[key as keyof IUtm] || "other";

        return { ...acc, [utm as keyof IUtmObject]: value };
      }, {});
    } catch (err) {
      console.error("UtmService extractUTMSfromCookie error");
      Sentry.captureException("UtmService extractUTMSfromCookie error: " + err);
    }
    return data || {};
  }
}

export default new UtmService();
