import MyRentalsRequest from "@/api/request";
import { IContactPerson } from "@/models/ContactPerson";

export default class ContactApi {
  private request: MyRentalsRequest;

  constructor(request: MyRentalsRequest) {
    this.request = request;
  }

  async retrieveContactPerson(uuid: string): Promise<IContactPerson> {
    const url = `/user/v1/rentals/my-housfy/property/${uuid}/housfy-agent`;
    return this.request.call<IContactPerson>({ method: "GET", url });
  }
}
