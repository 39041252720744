import { Environment } from "@/enums/Config";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import * as Sentry from "@sentry/browser";
import { Route } from "vue-router";
import Events from "./Trackings/Events";
import { IPageData, IExperimentEventData, IEventData } from "@/models/GtagGA";
export default class GoogleAnalyticsService extends Events {
  pageView(route: Route) {
    try {
      if (!window?.dataLayer || !route) return;
      const pageData = { page: route.path, location: route.path };
      this.trackPageview(pageData);
    } catch (err) {
      console.error("GoogleAnalyticsService pageView error");
      Sentry.captureException("GoogleAnalyticsService pageView error: " + err);
    }
  }

  private trackPageview(pageData: IPageData) {
    try {
      this.debugPageview(pageData);
      gtagGA("myhousfy", pageData);
    } catch (err) {
      console.error("GoogleAnalyticsService trackObjective error");
      Sentry.captureException(
        "GoogleAnalyticsService trackObjective error: " + err
      );
    }
  }

  private debugPageview(pageData: IPageData) {
    try {
      if (MyRentalsConfig.env().ENVIRONMENT === Environment.PROD) return;
      console.info(
        `[Google Analytics] trackPageview ${pageData.location} ${pageData.page}`
      );
    } catch (err) {
      console.error("GoogleAnalyticsService debugPageView error");
      Sentry.captureException(
        "GoogleAnalyticsService debugPageView error: " + err
      );
    }
  }
}

export function gtagGA(
  type: string,
  eventData: IPageData | IExperimentEventData | IEventData
) {
  if (!window.dataLayer) window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ...eventData, event: type });
}
