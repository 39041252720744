import MyRentalsRequest from "@/api/request";
import { IOwnerProperties } from "@/models/OwnerProperties";
import { IOwnerLeads } from "@/models/OwnerLeads";

export default class OwnerApi {
  private request: MyRentalsRequest;

  constructor(request: MyRentalsRequest) {
    this.request = request;
  }

  async retrieveOwnerProperties(): Promise<IOwnerProperties> {
    const url = `/user/v1/rentals/my-housfy/owner-properties`;
    return this.request.call<IOwnerProperties>({ method: "GET", url });
  }

  async retrieveOwnerLeads(): Promise<IOwnerLeads> {
    const url = `/user/v1/rentals/my-housfy/owner-leads`;
    return this.request.call<IOwnerLeads>({ method: "GET", url });
  }
}
