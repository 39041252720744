/* eslint-disable no-unused-vars */
export enum PropertyStatusCategory {
  RE_PUBLISHED = "RE published",
  RENTALS = "RNT",
  RNT_RENTED_IN_MANAGEMENT = "RNT rented in management",
  RNT_RENTED_NO_MANAGEMENT = "RNT rented no management",
  PENDING_AD = "RNT pending ad",
  PUBLISHED_AD = "RNT published ad",
  OLD_MYH = "old myh",
}

export enum OwnerLeadStatusCategory {
  PENDING_AD = "PendingAd",
  PUBLISHED_AD = "PublishedAd",
  RESERVATION_SIGNED_PENDING_LEASING = "ReservationSignedPendingRentalLeasingSignature",
  RENTED_IN_MANAGEMENT = "RentedInManagement",
  RENTED_NO_MANAGEMENT = "RentedNoManagement",
  FINALIZED = "Finalized",
  LOST = "Lost",
}
