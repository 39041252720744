import MyRentalsRequest from "@/api/request";
import {
  IDocuments,
  IDocumentsInvoices,
  IDocumentsLeasing,
} from "@/models/Documents";

const baseURL = (uuid: string): string =>
  `/user/v1/rentals/my-housfy/property/${uuid}/documents`;

export default class PropertyApi {
  private request: MyRentalsRequest;

  constructor(request: MyRentalsRequest) {
    this.request = request;
  }

  async retrieveInvoices(uuid: string): Promise<IDocumentsInvoices> {
    const url = `${baseURL(uuid)}/invoices`;
    return this.request.call<IDocumentsInvoices>({ method: "GET", url });
  }

  async retrieveProperty(uuid: string): Promise<IDocuments> {
    const url = `${baseURL(uuid)}/property`;
    return this.request.call<IDocuments>({ method: "GET", url });
  }

  async retrieveLeasing(
    uuid: string,
    leasingId: number
  ): Promise<IDocumentsLeasing> {
    const url = `${baseURL(uuid)}/leasing/${leasingId}`;
    return this.request.call<IDocumentsLeasing>({ method: "GET", url });
  }
}
