import { Environment } from "@/enums/Config";
import { gtagGA } from "@/services/marketing-services/GoogleAnalyticsService";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import * as Sentry from "@sentry/browser";
import Triggers from "./Triggers";

export default abstract class Events extends Triggers {
  trackRNTLeadOwnerGoal2() {
    this.trackObjective("rental", "lead", "owner");
  }

  trackRNTLeadViableGoal4(label: string | null = null) {
    this.trackObjective("lead_rental", "NUE_rental", label);
  }

  private trackObjective(
    category: string,
    action: string | null = null,
    label: string | null = null
  ) {
    try {
      gtagGA("myhousfy_event", {
        event_category: category,
        event_action: action,
        event_label: label,
      });
      this.debugObjective(category, action, label);
    } catch (err) {
      console.error("GoogleAnalyticsService trackObjective error");
      Sentry.captureException(
        "GoogleAnalyticsService trackObjective error: " + err
      );
    }
  }

  private debugObjective(
    category: string,
    action: string | null,
    label: string | null
  ) {
    try {
      if (MyRentalsConfig.env().ENVIRONMENT === Environment.PROD) return;
      console.info(
        `[Google Analytics] TrackObjective ${category} ${action} ${label}`
      );
    } catch (err) {
      console.error("GoogleAnalyticsService debugObjective error");
      Sentry.captureException(
        "GoogleAnalyticsService debugObjective error: " + err
      );
    }
  }
}
