import { API_ENDPOINTS_CACHE_WHITE_LIST } from "@/constants/ApiEndpointsValidatorsLists";

class CacheService {
  private responses: { [key: string]: Promise<any> };
  private userToken: string | null;

  constructor() {
    this.responses = {};
    this.userToken = null;
  }

  public isCacheable(requestUrl?: string, method?: string): boolean {
    if (!requestUrl || method?.toLocaleLowerCase() !== "get") return false;
    return this.isRequestUrlInWhiteList(requestUrl);
  }

  public checkUserToken(token: string): void {
    if (token === this.userToken) return;
    this.clean();
    this.userToken = token;
  }

  private isRequestUrlInWhiteList(url?: string): boolean {
    if (!url) return false;
    return API_ENDPOINTS_CACHE_WHITE_LIST.some((item: RegExp) =>
      item.test(url)
    );
  }

  public addToCache(requestUrl?: string, data?: Promise<any>): void {
    if (!requestUrl || !data) return;
    this.responses[requestUrl] = data;
  }

  public getFromCache(requestUrl?: string): Promise<any> | null {
    if (!requestUrl) return null;
    return this.responses[requestUrl];
  }

  public removeFromCache(requestUrl?: string): void {
    if (!requestUrl) return;
    delete this.responses[requestUrl];
  }

  public clean(): void {
    this.responses = {};
  }
}

export default new CacheService();
