import { ErrorCode, ApiErrorCode } from "@/enums/ErrorCode";
import { IHousfyError, IErrorData } from "@/models/HousfyError";

export default class HousfyError implements IHousfyError {
  public code: ErrorCode | ApiErrorCode;
  public message?: string;
  public status?: number;
  public error?: IErrorData;

  constructor(
    code: ErrorCode | ApiErrorCode,
    message?: string,
    status?: number,
    error?: IErrorData
  ) {
    this.code = code;
    this.message = message;
    this.status = status;
    this.error = error;
  }
}
