import { IPropertyState } from "@/models/Store";
import { IPropertyInfo } from "@/models/PropertyInfo";
import { IPropertyHistoryItems } from "@/models/PropertyHistory";

const state: IPropertyState = {
  propertyInfo: undefined,
  propertyHistory: undefined,
};

export default {
  namespaced: true,
  state,
  mutations: {
    SET_PROPERTY_INFO: (state: IPropertyState, propertyInfo: IPropertyInfo) => {
      state.propertyInfo = propertyInfo;
    },
    SET_PROPERTY_HISTORY: (
      state: IPropertyState,
      propertyHistory: IPropertyHistoryItems
    ) => {
      state.propertyHistory = propertyHistory;
    },
  },
  actions: {},
  getters: {
    propertyInfo: (state: IPropertyState) => state.propertyInfo,
    propertyHistory: (state: IPropertyState) => state.propertyHistory,
  },
};
