import { Environment } from "@/enums/Config";
import { gtagGA } from "@/services/marketing-services/GoogleAnalyticsService";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import * as Sentry from "@sentry/browser";

export default abstract class Triggers {
  /* EXAMPLE. REMOVE WHEN REAL TRACKINGS ARE ADDED, OR LEAVE IF NECESSARY */
  public RNTQualified(id: number) {
    this.trackTrigger("RNT_qualified", "rentals", "RNT_qualified", null, id);
  }
  /*END OF EXAMPLE*/
  private trackTrigger(
    trigger: string,
    category: string,
    action: string | null = null,
    label: string | null = null,
    id: number | null = null
  ) {
    try {
      gtagGA(trigger, {
        event_category: category,
        event_action: action,
        event_label: label,
        event_id: id,
      });
      this.debugTrigger(trigger, category, action, label, id);
    } catch (err) {
      console.error("GoogleAnalyticsService trackObjective error");
      Sentry.captureException(
        "GoogleAnalyticsService trackObjective error: " + err
      );
    }
  }

  private debugTrigger(
    trigger: string,
    category: string,
    action: string | null,
    label: string | null,
    id: number | null
  ) {
    try {
      if (MyRentalsConfig.env().ENVIRONMENT === Environment.PROD) return;
      console.info(
        `[Google Analytics] TrackObjective ${trigger} ${category} ${action} ${label} ${id}`
      );
    } catch (err) {
      console.error("GoogleAnalyticsService debugObjective error");
      Sentry.captureException(
        "GoogleAnalyticsService debugObjective error: " + err
      );
    }
  }
}
