
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "@/../tailwind.config.js";

const tailwindBreakpoints = resolveConfig(tailwindConfig).theme.screens;
const breakpointList: [string, string][] = Object.entries(
  tailwindBreakpoints
).filter(([, value]) => typeof value === "string") as [string, string][];

@Component({
  computed: {
    ...mapGetters("info", {
      $mq: "getDevice",
    }),
    $mqLg() {
      return this.mq("lg");
    },
    $mqMd() {
      return this.mq("md");
    },
    $mqSm() {
      return this.mq("sm");
    },
    $mqXs() {
      return this.mq("xs");
    },
    $mq2xs() {
      return this.mq("2xs");
    },
    $mqd() {
      return this.mq("lg", "md");
    },
    $mqm() {
      return this.mq("2xs", "xs");
    },
    $mqt() {
      return this.mq("sm");
    },
  },
})
export default class MqComponent extends Vue {
  mq(...options: string[]) {
    const breakpointsToCheck = Array.from(options);
    return breakpointsToCheck.includes(this.$mq);
  }
  parseClientWidth() {
    const device = this.getClientDeviceTypeByWidth();
    const deviceName =
      device === "lg" ? "desktop" : device === "md" ? "tablet" : "mobile";
    document.device = deviceName;
    this.$store.commit("info/SET_DEVICE", device);
  }
  getClientDeviceTypeByWidth() {
    const { clientWidth } = document?.documentElement || {};

    const breakpoint = breakpointList
      .filter(([, value]) => typeof value === "string")
      .sort(([, a], [, b]) => parseInt(b) - parseInt(a))
      .find(([, value]) => clientWidth >= parseInt(value));

    return breakpoint ? breakpoint[0] : "2xs";
  }
}
