import propertyGuard from "@/router/middleware/PropertyGuard_REFACTOR_OLD";

export default [
  {
    path: "/property/:propertyUuid/documents",
    name: "documents",
    beforeEnter: propertyGuard,
    component: () =>
      import(
        /* webpackChunkName: "Documents" */ "../views/property/Documents.vue"
      ),
  },
  {
    path: "/property/:propertyUuid/accounting",
    name: "accounting",
    beforeEnter: propertyGuard,
    component: () =>
      import(
        /* webpackChunkName: "Accounting" */ "../views/property/Accounting.vue"
      ),
  },
  {
    path: "/property/:propertyUuid/historical",
    name: "historical",
    beforeEnter: propertyGuard,
    component: () =>
      import(
        /* webpackChunkName: "Historical" */ "../views/property/Historical.vue"
      ),
  },
  {
    path: "/property/:propertyUuid/documents/leasing/:leasingId",
    name: "documentsLeasing",
    beforeEnter: propertyGuard,
    component: () =>
      import(
        /* webpackChunkName: "DocumentsLeasing" */ "../views/property/DocumentsLeasing.vue"
      ),
  },
  {
    path: "/property/:propertyUuid/documents/invoices",
    name: "documentsInvoices",
    beforeEnter: propertyGuard,
    component: () =>
      import(
        /* webpackChunkName: "DocumentsInvoices" */ "../views/property/DocumentsInvoices.vue"
      ),
  },
  {
    path: "/property/:propertyUuid/documents/property",
    name: "documentsProperty",
    beforeEnter: propertyGuard,
    component: () =>
      import(
        /* webpackChunkName: "DocumentsProperty" */ "../views/property/DocumentsProperty.vue"
      ),
  },
  {
    path: "/property/:propertyUuid/:dealId?",
    name: "property",
    beforeEnter: propertyGuard,
    component: () =>
      import(/* webpackChunkName: "Main" */ "../views/property/Main.vue"),
  },
];
