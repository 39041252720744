import MyRentalsConfig from "@/services/MyRentalsConfig";
import { IExternalRoute, IRouteOptions } from "@/models/IRoute";
import { OperationType } from "@/enums/OperationType";
import { PropertyTypePlural } from "@/enums/PropertyType";

class MYHExternalRoutes {
  private WEBAPP = MyRentalsConfig.config().WEBAPP_URL;
  private MYREALESTATE = MyRentalsConfig.config().MYREALESTATE_URL;
  private MYRENTALS = MyRentalsConfig.config().MYRENTALS_URL;
  private MYMORTGAGES = MyRentalsConfig.config().MYMORTGAGES_URL;
  private MYHOUSFY = MyRentalsConfig.config().MYHOUSFY_URL;

  /* WEBAPP */
  login(next?: string): IExternalRoute {
    const query = next ? "?next=" + next : "";
    return this.createRoute(this.WEBAPP, `/login${query}`, {
      cannotBeImpersonated: true,
    });
  }
  logout(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/logout", {
      cannotBeImpersonated: true,
    });
  }
  home(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/", {
      cannotBeImpersonated: true,
    });
  }
  help(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/help");
  }
  visits(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/dashboard/visitas");
  }
  documents(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/dashboard/documentos");
  }
  favorites(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/dashboard/favoritos");
  }
  savedSearches(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/dashboard/saved-searches");
  }
  referralFriend(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/dashboard/referral-friend");
  }
  profile(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/dashboard/perfil");
  }
  terms(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/legal/terminos", {
      cannotBeImpersonated: true,
    });
  }
  privacyProviders(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/legal/privacidad-proveedores", {
      cannotBeImpersonated: true,
    });
  }
  privacyUsers(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/legal/privacidad-usuarios", {
      cannotBeImpersonated: true,
    });
  }
  cookies(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/legal/cookies", {
      cannotBeImpersonated: true,
    });
  }
  withdrawal(): IExternalRoute {
    return this.createRoute(this.WEBAPP, "/legal/desistimiento", {
      cannotBeImpersonated: true,
    });
  }
  dashboardProperty(uuid: string, dealId?: number): IExternalRoute {
    const deal = dealId ? "/" + dealId : "";
    return this.createRoute(this.WEBAPP, `/dashboard/inmuebles/${uuid}${deal}`);
  }
  dashboardNewProperty(uuid: string, dealId?: string): IExternalRoute {
    const deal = dealId ? "/" + dealId : "";
    return this.createRoute(
      this.WEBAPP,
      `/dashboard-new/inmuebles/${uuid}${deal}`
    );
  }
  portal(
    operationTypeCode?: OperationType,
    propertyTypeCode?: PropertyTypePlural
  ): IExternalRoute {
    const operationType = operationTypeCode || OperationType.RENTAL;
    const propertyType = propertyTypeCode || PropertyTypePlural.VIVIENDAS;
    return this.createRoute(this.WEBAPP, `/${operationType}-${propertyType}`, {
      cannotBeImpersonated: true,
      shouldOpenNewWindow: true,
    });
  }
  propertyPreview(uuid: string): IExternalRoute {
    return this.createRoute(this.WEBAPP, `/preview/property?uuid=${uuid}`, {
      cannotBeImpersonated: true,
      shouldOpenNewWindow: true,
    });
  }
  dashboardPropertyCreation(
    uuid: string,
    hasAppointmentManager?: boolean
  ): IExternalRoute {
    return this.createRoute(
      this.WEBAPP,
      `/dashboard/inmuebles/creation/${uuid}?has_am=${hasAppointmentManager}`
    );
  }
  dashboardPropertyPublished(
    uuid: string,
    hasAppointmentManager?: boolean
  ): IExternalRoute {
    return this.createRoute(
      this.WEBAPP,
      `/dashboard/inmuebles/published/${uuid}?has_am=${hasAppointmentManager}`
    );
  }

  /* MYH */
  myhHome(): IExternalRoute {
    return this.createRoute(this.MYHOUSFY, "/");
  }
  tools(): IExternalRoute {
    return this.createRoute(this.MYHOUSFY, "/tools");
  }
  noImpersonatePermission(): IExternalRoute {
    return this.createRoute(this.MYHOUSFY, "/no-impersonate-permission", {
      cannotBeImpersonated: true,
    });
  }
  invalidImpersonatedUser(): IExternalRoute {
    return this.createRoute(this.MYHOUSFY, "/invalid-impersonated-user", {
      cannotBeImpersonated: true,
    });
  }
  contact(): IExternalRoute {
    return this.createRoute(this.MYHOUSFY, "/contact");
  }

  /* MYRE */
  valuationTool(): IExternalRoute {
    return this.createRoute(this.MYREALESTATE, "/tools/valuation");
  }

  myRealEstateProperty(uuid: string): IExternalRoute {
    return this.createRoute(this.MYREALESTATE, `/property/${uuid}`);
  }

  /* MYRNT */

  /* MYMO */
  simulatorTool(): IExternalRoute {
    return this.createRoute(this.MYMORTGAGES, "/tools/mortgage-simulator");
  }

  private createRoute(
    domain: string,
    path: string,
    options?: IRouteOptions
  ): IExternalRoute {
    return { domain, path, options, isExternal: true };
  }
}
export default new MYHExternalRoutes();
