import Vue from "vue";
import VueI18n from "vue-i18n";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { Country } from "@/enums/Config";
import es from "@/assets/locales/es.json";
import it from "@/assets/locales/it.json";
import pt from "@/assets/locales/pt.json";
import en from "@/assets/locales/en.json";

Vue.use(VueI18n);

const translations = {
  es,
  it,
  pt,
  en,
};

export default new VueI18n({
  locale: MyRentalsConfig.country(),
  fallbackLocale: Country.ES,
  messages: translations,
});

export const fixNumberLocale = (locale: string): string => {
  if (locale === "es") {
    // Chrome toLocaleString implementation for "es" language doesn't add point separator on thousand units (1000 to 9999, instead of 1.000 to 9.999)
    // So, as workaround, we use "it", as "es" look-alike format, also with proper point separator on thousand units.
    return "it";
  }
  return locale;
};
