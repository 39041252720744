import Vue from "vue";
import VueRouter, { RawLocation, RouteConfig } from "vue-router";
import StitchService from "@/services/StitchService";
import MarketingService from "@/services/MarketingService";
import * as Sentry from "@sentry/browser";
import AuthService from "@/services/AuthService";
import { IMPERSONATE_URL_PREFIX } from "@/constants/Impersonate";
import { IExternalRoute, IRoute, IExternalRouteHref } from "@/models/IRoute";
import ExperimentService from "@/services/ExperimentService";
import errors from "@/router/errors";
import property from "@/router/property";
import property_REFACTOR_OLD from "@/router/property_REFACTOR_OLD";

Vue.use(VueRouter);

const getRoutes = (): Array<RouteConfig> => {
  let routes: Array<RouteConfig> = [
    {
      path: "/tenant",
      name: "tenant",
      component: () =>
        import(/* webpackChunkName: "Tenant" */ "../views/Tenant.vue"),
    },
    {
      path: "/document-viewer",
      name: "documentViewer",
      component: () =>
        import(
          /* webpackChunkName: "Signed documents" */ "../views/DocumentsViewer.vue"
        ),
    },
  ];

  // We check "old" url search param to use routes for old or new MyRentals views
  const params = new URLSearchParams(window.location.search);
  if (params.has("old")) {
    routes = [
      ...routes,
      {
        path: "/owner",
        name: "owner",
        component: () =>
          import(
            /* webpackChunkName: "Owner" */ "../views/Owner_REFACTOR_OLD.vue"
          ),
      },
      ...property_REFACTOR_OLD,
    ];
  } else {
    routes = [
      ...routes,
      {
        path: "/owner",
        name: "owner",
        component: () =>
          import(/* webpackChunkName: "Owner" */ "../views/Owner.vue"),
      },
      ...property,
    ];
  }

  /**
   * Put all the routes and routeModules above this line.
   * The error routes should be the last routes
   */
  routes = [...routes, ...errors];

  return routes.map((route) => ({
    ...route,
    alias: `/${IMPERSONATE_URL_PREFIX}/:userId${route.path}`,
  }));
};

const router = new VueRouter({
  mode: "history",
  routes: getRoutes(),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.afterEach((to, from) => {
  try {
    if (from.name) MarketingService.analytics().pageView(to);
  } catch (err) {
    console.error("PageView not tracked");
    Sentry.captureException("PageView not tracked: " + err);
  }
  try {
    StitchService.trackEvent("page_view");
  } catch (err) {
    console.error("[Stitch] Stitch pageView Error ", err);
    Sentry.captureException("[Stitch] Stitch pageView Error " + err);
  }
});

router.beforeEach(async (to, from, next) => {
  const guard = await AuthService.authGuard(to?.meta?.public);
  if (!to?.meta?.public && guard !== "PROFILE" && guard !== "OK") return;

  await ExperimentService.setExperiments(to);

  if (!from.name) MarketingService.google().init();

  next();
});

const addImpersonate = (path: string): string => {
  const impersonateUserId = AuthService.impersonateUserId();
  let impersonatePrefix = `/${IMPERSONATE_URL_PREFIX}/${impersonateUserId}`;

  if (path?.includes("dashboard")) {
    impersonatePrefix = "/dashboard" + impersonatePrefix;
    path = path.replace("/dashboard", "");
  }
  return `${impersonatePrefix}${path}`;
};

export const resolveRoutePath = (
  to: IExternalRouteHref | IRoute | IExternalRoute
): string => {
  let domain = to.domain;
  let path = to.isExternal
    ? to.path
    : router.resolve(to as RawLocation).route.path;

  if (to.href) {
    const url = new URL(to.href);
    domain = url.origin;
    path = `${url.pathname}${url.search}`;
  }

  if (!path) path = "/";
  if (!to.options?.cannotBeImpersonated && AuthService.isImpersonate())
    path = addImpersonate(path);
  return `${domain || ""}${path}`;
};

export default router;
