/*
How to get an experiment variant:
get myhArchitectureVariant(): Variant | undefined {
  return this.experimentVariants[EXPERIMENTS.MYH_ARCHITECTURE.id];
}
*/

import { Route } from "vue-router";
import {
  EXPERIMENTS_BY_PERCENTAGE,
  EXPERIMENTS_BY_USER_ID,
} from "@/constants/Experiments";
import storage from "@/plugins/storage";
import { CookiesKeys } from "@/enums/Storage";
import api from "@/api";
import * as Sentry from "@sentry/browser";
import AuthService from "./AuthService";
import type {
  Variant,
  NumericVariant,
  IExperimentByPercentage,
  IExperimentByUserId,
} from "@/models/Experiments";
import { UserId } from "@/models/User";
interface IExperimentVariants {
  [id: string]: Variant;
}

class ExperimentService {
  private experimentVariants: IExperimentVariants = {};

  private shouldExcludeNavigator = (): boolean => {
    return (
      !!storage.getCookiesItem(CookiesKeys.IS_AUTOMATIC_TESTING_RF) ||
      this.checkIfIsBot(navigator.userAgent)
    );
  };

  private shouldExcludePath = (
    experiment: IExperimentByPercentage | IExperimentByUserId,
    to: Route
  ): boolean => {
    return !!(
      (experiment.pathPattern &&
        !new RegExp(experiment.pathPattern).test(to.path)) ||
      (experiment.paths && !experiment.paths.includes(to.path))
    );
  };

  private shouldExcludeUserId = (
    experiment: IExperimentByUserId,
    userId: UserId | undefined
  ): boolean => {
    if (!userId) return true;
    return !experiment.users.includes(userId);
  };

  async setExperiments(to: Route) {
    this.experimentVariants = {};
    if (this.shouldExcludeNavigator()) return;
    await this.setExperimentsByPercentage(to);
    this.setExperimentsByUserId(to);
  }

  setExperimentsByUserId(to: Route) {
    Object.values(EXPERIMENTS_BY_USER_ID).forEach((experiment) => {
      if (this.shouldExcludePath(experiment, to)) return;
      if (this.shouldExcludeUserId(experiment, this.currentUserId)) return;
      this.experimentVariants[experiment.id] = experiment.variant;
    });
  }

  async setExperimentsByPercentage(to: Route) {
    for (const experiment of Object.values(EXPERIMENTS_BY_PERCENTAGE)) {
      if (this.shouldExcludePath(experiment, to)) continue; // continue no next experiment

      try {
        let numericVariant: NumericVariant;
        const cookiesVariant = this.getVariantFromCookie(
          CookiesKeys.EXPERIMENT,
          experiment.id,
          experiment.lastReset
        );
        if (cookiesVariant !== null)
          numericVariant = +cookiesVariant as NumericVariant;
        else {
          let { options } = experiment;
          if (experiment.adminOptionsId)
            options = await this.getOptionsFromAdmin(experiment);

          if (options) numericVariant = this.getExperimentVariant(options);
          else {
            const errorMessage = `Experiment ${experiment.id} does not have the options values set and it doesnt get them from admin`;
            Sentry.captureException(errorMessage);
            console.error(errorMessage);
            return;
          }
          if (numericVariant === null || numericVariant === undefined) return;
        }
        storage.setCookiesItemWithId(
          CookiesKeys.EXPERIMENT,
          experiment.id,
          `${numericVariant}|${new Date().getTime()}`
        );

        this.experimentVariants[experiment.id] =
          this.numericToStringvariantMapper(numericVariant);
      } catch (err) {
        console.error("Error getting experiment options: " + err);
        Sentry.captureException("Error getting experiment options: " + err);
      }
    }
  }

  private checkIfIsBot(userAgent: string): boolean {
    const bots = ["Google", "bingbot"];
    return bots.some((bot) => userAgent.includes(bot));
  }

  private getExperimentVariant(options: number[]): NumericVariant {
    const random = Math.random();
    let variant = 0;
    let accumulated = 0;
    options.forEach((o: number, i: number) => {
      if (accumulated < random && random < accumulated + o) variant = i;
      accumulated += o;
    });
    return variant as NumericVariant;
  }

  private numericToStringvariantMapper(
    numericVariant: NumericVariant
  ): Variant {
    return numericVariant === 0 ? "A" : numericVariant === 1 ? "B" : "C";
  }

  private async getOptionsFromAdmin(
    experiment: IExperimentByPercentage
  ): Promise<number[] | null> {
    if (!experiment.adminOptionsId) return null;
    const response = await api
      .experiments()
      .getExperimentOptions(experiment.adminOptionsId);

    return [
      response.constrains[0].userPercentage * 0.01,
      1 - response.constrains[0].userPercentage * 0.01,
    ];
  }

  private getVariantFromCookie(
    key: string,
    id: string,
    lastResetOfExperiment: number[]
  ): string | null {
    const experimentCookie = storage.getCookiesItemWithId(
      CookiesKeys.EXPERIMENT,
      id
    );
    if (experimentCookie !== null && experimentCookie !== undefined) {
      const [variant, lastReset] = experimentCookie.split("|");
      if (!lastReset) return variant;
      if (Number(lastReset) > Number(lastResetOfExperiment[Number(variant)]))
        return variant;
    }
    return null;
  }

  get allExperimentVariants(): IExperimentVariants {
    return this.experimentVariants;
  }

  get allExperiments() {
    return [
      ...Object.values(EXPERIMENTS_BY_PERCENTAGE),
      ...Object.values(EXPERIMENTS_BY_USER_ID),
    ];
  }

  get activeExperiments() {
    const activeExperiments = [];
    for (const experiment of this.allExperiments) {
      if (this.experimentVariants[experiment.id])
        activeExperiments.push({
          name: experiment.name,
          id: experiment.id,
          variant: this.experimentVariants[experiment.id],
        });
    }
    return activeExperiments;
  }

  get currentUserId(): UserId | undefined {
    return AuthService.currentUser.id;
  }
}

export default new ExperimentService();
