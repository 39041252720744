import MyRentalsRequest from "@/api/request";
import { IPropertyStatusCategory } from "@/models/PropertyStatusCategory";

export default class HomePropertyInfoApi {
  private request: MyRentalsRequest;

  constructor(request: MyRentalsRequest) {
    this.request = request;
  }

  async get(uuid: string): Promise<IPropertyStatusCategory> {
    const url = `/user/v1/lot/shared/my-housfy/property/${uuid}/status-category`;

    return this.request.call<IPropertyStatusCategory>({
      method: "GET",
      url,
    });
  }
}
