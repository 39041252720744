/* eslint-disable no-unused-vars */
export enum PropertyTypePlural {
  VIVIENDAS = "inmuebles",
  OFICINAS = "oficinas",
  SOLARES = "solares",
  EDIFICIOS = "edificios",
  GARAJES = "garajes",
  PISOS = "pisos",
  ATICOS = "aticos",
  BAJOS = "bajos",
  DUPLEX = "duplex",
  CASAS = "casas",
}
