import Vue from "vue";
import Vuex from "vuex";
import info from "./info";
import owner from "./owner";
import property from "./property";
import contact from "./contact";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { info, owner, property, contact },
});
