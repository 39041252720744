import Vue from "vue";
import Money from "@/models/Money";
import { fixNumberLocale } from "@/plugins/i18n";

Vue.filter("currency", (amount = 0, options = { currency: "EUR" }) => {
  const money = new Money(amount);
  money.withOptions(options);
  return money.format();
});

Vue.filter("number", (value: number) => {
  if (!value || isNaN(value)) return 0;
  return value.toLocaleString(fixNumberLocale("es"));
});

Vue.filter("numberSymbol", (value: number) => {
  if (value > 0) return "+" + value;
  return value;
});
