import { IInfoState } from "@/models/Store";
import { IUserDevice } from "@/models/Device";

const state: IInfoState = {
  userDevice: undefined,
};

export default {
  namespaced: true,
  state,
  mutations: {
    SET_DEVICE: (state: IInfoState, device: IUserDevice) => {
      state.userDevice = device;
    },
  },
  actions: {},
  getters: {
    getDevice: (state: IInfoState) => state.userDevice,
  },
};
