
import { IRoute, IExternalRoute, IExternalRouteHref } from "@/models/IRoute";
import { resolveRoutePath } from "@/router";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class HousfyRouterMixin extends Vue {
  $navigate(route: IRoute | IExternalRoute | IExternalRouteHref) {
    const to = resolveRoutePath(route);
    if (route.options?.shouldOpenNewWindow) window.open(to);
    else if (route.isExternal)
      /* eslint-disable-next-line xss/no-location-href-assign */
      location.href = to;
    else this.$router.push(to);
  }
}
