import { NavigationGuardNext, Route } from "vue-router";
import api from "@/api";
import { PropertyStatusCategory } from "@/enums/StatusCategory";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { resolveRoutePath } from "@/router";

export default async function (to: Route, _: Route, next: NavigationGuardNext) {
  const propertyUuid = to.params.propertyUuid;
  const deal = to.params.deal;

  try {
    let newPath = "";

    const propertyStatusCategory = (
      await api.propertyStatusCategory().get(propertyUuid)
    ).propertyStatusCategory;

    if (to.meta?.allowedCategories.includes(propertyStatusCategory)) {
      const statusCategoriesWithoutDeal = [
        PropertyStatusCategory.RNT_RENTED_IN_MANAGEMENT,
        PropertyStatusCategory.RNT_RENTED_NO_MANAGEMENT,
      ];
      if (
        statusCategoriesWithoutDeal.includes(propertyStatusCategory) &&
        to.params.deal
      ) {
        newPath = to.path.substring(0, to.path.lastIndexOf("/"));
      } else {
        return next();
      }
    }

    if (propertyStatusCategory === PropertyStatusCategory.OLD_MYH)
      newPath = resolveRoutePath(
        deal
          ? MYHExternalRoutes.dashboardNewProperty(propertyUuid, deal)
          : MYHExternalRoutes.dashboardProperty(propertyUuid)
      );
    else if (propertyStatusCategory === PropertyStatusCategory.RE_PUBLISHED)
      newPath = resolveRoutePath(
        MYHExternalRoutes.myRealEstateProperty(propertyUuid)
      );

    if (newPath)
      // eslint-disable-next-line xss/no-location-href-assign
      location.href = newPath;
    else {
      next();
    }
  } catch (error) {
    // eslint-disable-next-line xss/no-location-href-assign
    location.href = "/not-found";
  }
}
