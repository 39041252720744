import propertyGuard from "@/router/middleware/PropertyGuard";
import { PropertyStatusCategory } from "@/enums/StatusCategory";

export default [
  {
    path: "/property/:propertyUuid/history",
    name: "history",
    beforeEnter: propertyGuard,
    meta: {
      allowedCategories: [
        PropertyStatusCategory.RNT_RENTED_IN_MANAGEMENT,
        PropertyStatusCategory.RNT_RENTED_NO_MANAGEMENT,
      ],
    },
    component: () =>
      import(/* webpackChunkName: "History" */ "../views/property/History.vue"),
  },
  {
    path: "/property/:propertyUuid/:deal?",
    name: "property",
    beforeEnter: propertyGuard,
    meta: {
      allowedCategories: [
        PropertyStatusCategory.RNT_RENTED_IN_MANAGEMENT,
        PropertyStatusCategory.RNT_RENTED_NO_MANAGEMENT,
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "Property" */ "../views/property/Property.vue"
      ),
  },
];
